import { StrictMode, useState, useCallback, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";

const App = () => {
  const [count, setCount] = useState(0);
  const increment = useCallback(() => setCount((c) => c + 1), []);

  useEffect(() => {
    const interval = setInterval(increment, 1000);
    return () => clearInterval(interval);
  }, [increment]);

  return (
    <div>
      <h1>Hello, world!</h1>
      <p>Count: {count}</p>
      {/**
      <br />
      {isLoggedIn && <p>Logged in as {user?.username}</p>}
      <br />
      <LoginLogoutElement />
      <br />
      <h2>User Management</h2>
      {!isLoggedIn && (
        <div>
          <CreateUserForm />
        </div>
      )}
       */}
    </div>
  );
};

const Main = () => (
  <StrictMode>
    <App />
  </StrictMode>
);

createRoot(document.getElementById("root")!).render(<Main />);
